import Dexie from 'dexie';

const db = new Dexie('AgroCampo');

db.version(40).stores({
  usuario: `
    ++id,
    useidnuvem,
    usenome,
    usecpf,
    usesenha,
    usedataatualizacao
  `,
  veiculo: `
    ++id,
    veiidveiculo,
    veiidnuvem,
    veiplaca,
    veitipoveiculo,
    veimarca,
    veicapacidade,
    veifrota,
    veidataatualizacao,
    veitipomaquinario
  `,
  propriedade: `
    ++id,
    proidnuvem,
    proidpropriedade,
    pronome,
    prodataatualizacao
  `,
  operacao: `
    ++id,
    opeidnuvem,
    opeidoperacao,
    opedescricao,
    opedataatualizacao
  `,
  produto: `
    ++id,
    proidnuvem,
    proidproduto,
    prodescricao,
    procodigo,
    prodataatualizacao,
    proun,
    proprecoa
  `,
  estoque: `
    ++id,
    moeidnuvem,
    moeidmovimentacaoestoque,
    moedescricao,
    moecategoria,
    moeidproduto,
    moedataatualizacao
  `,
  localmovimentacaoestoque: `
    ++id,
    lmeidnuvem,
    lmeidlocalmovimentacaoestoque,
    lmedescricao,
    lmeidproduto,
    lmedataatualizacao
  `,
  pessoa: `
    ++id,
    pesidnuvem,
    pesidpessoa,
    pesnome,
    pesnomefantasia,
    pescpf,
    pesdataatualizacao,
    pespessoafuncionario,
    pesemail,
    pestelefone
  `,
  atividadeabastecimento: `
    ++id,
    ataidnuvem,
    ataidatividadeabastecimento,
    atadescricao,
    atadataatualizacao,
    atacodigo
  `,
  abastecimento: `
    ++id,
    abaidnuvem,
    abadata,
    abaoperacao,
    abafrota,
    abaoperadorveiculo,
    abaodometro,
    abatipoodometro,
    abaquantidade,
    abaorigem,
    abaoperador,
    abalocal,
    abaatividade,
    abadescricao,
    abafinalizado,
    abaproduto
  `,
  logexclusao: `
    ++id,
    loedataexclusao,
    loeidnuvem,
    loetabela,
    loeidusuario,
    loedatanuvem
  `,
  movimentacaoabastecimento: `
    ++id,
    moatipooperacao,
    moaidveiculo,
    moaidpessoa,
    moatipoodometro,
    moaodometro,
    moaquantidade,
    moaidprodutoorigem,
    moaidpessoaorigem,
    moaidpropriedade,
    moaidatividade,
    moaobservacao,
    moaidlocalarmazenamentoorigem,
    moadatacriacao,
    moaidusuario,
    moaidnuvem,
    moadataatualizacao
  `,
  lancamentoatividade: `
    ++id,
    laaidpropriedade,
    laaidatividade,
    laaidoperador,
    laadatacriacao,
    laaidnuvem,
    laaidusuario,
    laaidtalhao,
    laadatainicio,
    laadatatermino,
    laaobservacao,
    laadataatualizacao,
    laadatanuvem
  `,
  cultura: `
    ++id,
    culidnuvem,
    culidcultura,
    culdescricao,
    culidpropriedade,
    culdataatualizacao
  `,
  visitatecnica: `
    ++id,
    vitidnuvem,
    vitidusuario,
    vitidcultura,
    vitidprodutor,
    vitidpropriedade,
    vitdatacriacao,
    vitdataatualizacao
  `,
  visita: `
    ++id,
    visidnuvem,
    visidcultura,
    visidprodutor,
    visidpropriedade,
    visdatacriacao
  `,
  imagem: `
    ++id,
    idvisita,
    imgdata,
    imgdataanterior,
    imgnome,
    imgdescricao
  `,
  lancamentovisitatecnica: `
    ++id,
    lvtidlancamentovisitatecnica,
    lvtidvisita,
    lvtdatalancamento,
    lvtimagem,
    lvtnome,
    lvtdescricao,
    lvtaudio,
    lvtobservacao,
    lvtidtalhao,
    lvtvideo,
    lvtdataanterior,
    lvtdatanuvem,
    lvtidusuario,
    lvtaguardandomidia,
    lvtdataatualizacao,
    lvtidnuvem
  `,
  naturezaoperacao: `
    ++id,
    naoidnaturezaoperacao,
    naodescricao,
    naodataatualizacao
  `,
  notafiscal: `
    ++id,
    nofidnotafiscal,
    nofidnaturezaoperacao,
    nofdataatualizacao,
    nofidpessoa,
    noftotal,
    nofidtransportador,
    noftipofrete,
    nofidveiculo,
    nofespecie,
    nofdataemissao,
    nofnronota,
    nofserie,
    nofnomepessoa,
    nofplaca,
    nofidnuvem,
    nofidusuario,
    nofmensagemretornanfesefaz,
    nofcodigoretornanfesefaz,
    nofdatacriacao,
    nofprocessamento,
    nofdatasaida
  `,
  notafiscalitem: `
    ++id,
    nfiidnuvemnotafiscal,
    nfiidproduto,
    nfiidnuvem,
    nfidatanuvem,
    nfiquantidade,
    nfivalorunitario,
    nfivalortotal,
    nfiun,
    nfidescricao,
    nfidataatualizacao,
    nfidatacriacao,
    nfidatanotafiscal
  `,
  talhao: `
    ++id,
    talidtalhao,
    talidnuvem,
    taldescricao,
    taldataatualizacao,
    talcodigo
  `,
  worker: `
    ++id,
    worapibaseurl,
    wortoken,
    wordataultimaupdate,
    worpushkey
  `,
});

export default db;
